import React, { Component } from "react";
import { Helmet } from "react-helmet";

function ThankYouCK() {
    return (
        <Helmet>
            <meta property="og:url" content="https://divivgroup.lpages.co/thank-you-ck/" />
            <link rel="opengraph" href="https://divivgroup.lpages.co/thank-you-ck/" />
            <script src="//divivgroup.lpages.co/_/js/thank-you-ck/"></script>
            <style>{'html{font-family:"Roboto", sans-serif} strong { color: inherit; } section{ padding: 10px} a:hover{text-decoration:none} .css-1ml7zq4{background:#ffffff} #___gatsby, #gatsby-focus-wrapper{display:none}'}</style>
        </Helmet>
    )
}

export default ThankYouCK;